<template>
  <div class="viewpage">
    <!-- @mousewheel.prevent -->
    <div class="va-layout">
      <div class="row row-equal">
        <div class="flex xs6 md6 xl6">
          <!-- offset--xs1 offset--md1 offset--xl1 -->

          <!--<va-card
            titleOnImage
            :image="bigUrl"
            noPadding=false
          ></va-card>-->
          <img
            :src="bigUrl"
            style="width: 100%; height: 100%;"
          ></img>
        </div>

        <div class="flex xs4 sm4 xl4">
          <va-card style="width: 100%; height: 100%;">
            <div class="flex md12 sm12 xs12">
              <va-input v-model="qrcode" placeholder="请输入对应编号">
                <va-button slot="append" small @click="preview"
                  >开始预览</va-button
                >
              </va-input>
            </div>

            <va-item>
              <va-item-section>
                <va-item-label>编号：{{ this.qrcode }}</va-item-label>
                <!-- <va-item-label caption>You can send invitations via email or any messenger.</va-item-label> -->
              </va-item-section>
            </va-item>

            <va-item>
              <va-item-section>
                <va-item-label>已选择照片数：{{ checkNumber() }}</va-item-label>
                <!-- <va-item-label caption>You can send invitations via email or any messenger.</va-item-label> -->
              </va-item-section>
            </va-item>

            <!---
            <va-item>
              <va-item-section>
                <va-item-label>电子版价格：¥ {{ this.emoney }}</va-item-label>
              </va-item-section>
            </va-item>

            <va-item>
              <va-item-section>
                <va-item-label>照片打印价格：¥ {{ this.pmoney }}</va-item-label>
              </va-item-section>
            </va-item>
            -->

            <va-item>
              <va-item-section>
                <va-item-label>日期：{{ this.getdate() }}</va-item-label>
              </va-item-section>
            </va-item>

            <va-item>
              <va-item-section>
                <va-button @click="showConfirmEorder">购买电子版</va-button>
                <!--<va-button @click="porder">购买打印照片</va-button>-->
                <va-button @click="showConfirmPorder">购买打印照片</va-button>
              </va-item-section>
            </va-item>
          </va-card>
        </div>
      </div>

      <div class="row row-equal">
        <div
          class="flex xs2 md2 xl2"
          v-for="(info, idx) in photoList.slice(0, 10)"
          :key="idx"
        >
          <va-card
            overlay
            titleOnImage
            :image="info.url"
            @click.native="changeImage(idx)"
          >
            <va-toggle
              small
              slot="header"
              v-model="info.status"
              :label="info.selected"
              @click.native="checkNumber(idx)"
            />
          </va-card>
        </div>
      </div>

      <!-- <div class="va-row row wrap">
        <div class="flex xs12">
          <p align="center">预览并选择照片</p>
        </div>
      </div>-->
    </div>

    <va-modal
      v-model="showEorder"
      size="small"
      title="确认购买电子版照片"
      okText="已付款"
      cancelText="取消"
      @ok="eorder"
      @cancel="onCancel"
    />

    <va-modal
      v-model="showPorder"
      size="small"
      title="确认购买打印版照片"
      okText="已付款"
      cancelText="取消"
      @ok="porder"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcodejs2";
export default {
  name: "viewpage",
  data() {
    return {
      qrcode: "",
      showEorder: false,
      showPorder: false,
      qrcodeErrors: "",
      toastText: "",
      toastDuration: 2500,
      emoney: 0,
      pmoney: 0,
      toastPosition: "top-center",
      isToastFullWidth: false,
      bigUrl: require("../../../../../background/1.jpg"),
      photoDatas: [],
      photoList: [
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/1.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/2.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/3.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/4.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/5.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/6.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/7.jpg"),
        },
        {
          selected: "未选中",
          status: false,
          url: require("../../../../../background/8.jpg"),
        },
      ],
    };
  },
  methods: {
    eorder() {
      this.axios
        .post("/v1/picture/order", {
          qrcode: this.qrcode,
          money: this.emoney,
          username: sessionStorage["username"],
          desc: "电子版",
          status: this.photoList,
        })
        .then((res) => {
          console.log(res);
          console.log("now start");
          const instance1 = axios.create({
            baseURL: "http://localhost:8800",
            timeout: 5000,
          });
          // ====================
          this.photoDatas = [];
          // 每行32个英文字符，16个中文字符
          this.photoDatas.push({
            type: "text",
            payload:
              "      ==欢迎参观东方明珠==\n \n  请在三天内扫描二维码完成下载\n ",
          });
          for (var i = 0; i < this.photoList.length; i++) {
            if (this.photoList[i].status === true) {
              var div = document.createElement("div");
              console.log(this.photoList[i].url)
              var code = new QRCode(div, {
                text: this.photoList[i].url,
                width: 250,
                height: 250,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.L,
              });
              let canvas = code._el.querySelector("canvas");
              var base64Text = canvas.toDataURL("image/png");
              let payload = base64Text.slice(base64Text.indexOf("base64,") + 7);
              console.log(payload);
              this.photoDatas.push({ type: "image", payload: payload });
              this.photoDatas.push({
                type: "text",
                payload: " \n     ----------------------\n ",
              });
            }
          }
          instance1({
            url: "/print",
            method: "post",
            data: { printer: "receipt", data: this.photoDatas },
          })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
          // 收款小票
          const instance2 = axios.create({
            baseURL: "http://localhost:8800",
            timeout: 5000,
          });
          var receiptData = [];
          receiptData.push({
            type: "text",
            payload:
              "  东方明珠--AI图像合成销售系统\n \n            收银单据\n \n    ------------------------\n ",
          });
          receiptData.push({
            type: "text",
            payload: "    客户编码 : " + this.qrcode,
          });
          receiptData.push({ type: "text", payload: "    类型 : 电子版" });
          var choseCount = 0;
          for (var i = 0; i < this.photoList.length; i++) {
            if (this.photoList[i].status === true) {
              choseCount++;
            }
          }
          receiptData.push({
            type: "text",
            payload: "    数量 :  " + choseCount,
          });
          receiptData.push({
            type: "text",
            payload: "    金额 :  " + this.emoney,
          });
          receiptData.push({
            type: "text",
            payload: " \n    ------------------------",
          });
          receiptData.push({
            type: "text",
            payload: " \n    " + this.getdate() + " " + this.gettime(),
          });
          instance2({
            url: "/print",
            method: "post",
            data: { printer: "receipt", data: receiptData },
          })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    },
    porder() {
      this.axios
        .post("/v1/picture/order", {
          qrcode: this.qrcode,
          money: this.pmoney,
          username: sessionStorage["username"],
          desc: "打印版",
          status: this.photoList,
        })
        .then((res) => {
          console.log(res);
          console.log("now start");
          // 收款小票
          const instance2 = axios.create({
            baseURL: "http://localhost:8800",
            timeout: 5000,
          });
          var receiptData = [];
          receiptData.push({
            type: "text",
            payload:
              "  东方明珠--AI图像合成销售系统\n \n            收银单据\n \n    ------------------------\n ",
          });
          receiptData.push({
            type: "text",
            payload: "    客户编码 : " + this.qrcode,
          });
          receiptData.push({ type: "text", payload: "    类型 : 打印版" });
          var choseCount = 0;
          for (var i = 0; i < this.photoList.length; i++) {
            if (this.photoList[i].status === true) {
              choseCount++;
            }
          }
          receiptData.push({
            type: "text",
            payload: "    数量 :  " + choseCount,
          });
          receiptData.push({
            type: "text",
            payload: "    金额 :  " + this.pmoney,
          });
          receiptData.push({
            type: "text",
            payload: " \n    ------------------------",
          });
          receiptData.push({
            type: "text",
            payload: " \n    " + this.getdate() + " " + this.gettime(),
          });
          instance2({
            url: "/print",
            method: "post",
            data: { printer: "receipt", data: receiptData },
          })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
          // ====================
          this.photoDatas = [];
          // 每行32个英文字符，16个中文字符
          this.photoDatas.push({
            type: "text",
            payload:
              "      ==欢迎参观东方明珠==\n \n  请在三天内扫描二维码完成下载\n ",
          });
          for (var i = 0; i < this.photoList.length; i++) {
            if (this.photoList[i].status === true) {
              var div = document.createElement("div");
              console.log(this.photoList[i].url)
              var code = new QRCode(div, {
                text: this.photoList[i].url,
                width: 250,
                height: 250,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.L,
              });
              let canvas = code._el.querySelector("canvas");
              var base64Text = canvas.toDataURL("image/png");
              let payload = base64Text.slice(base64Text.indexOf("base64,") + 7);
              console.log(payload);
              this.photoDatas.push({ type: "image", payload: payload });
              this.photoDatas.push({
                type: "text",
                payload: " \n     ----------------------\n ",
              });
            }
          }
          instance2({
            url: "/print",
            method: "post",
            data: { printer: "receipt", data: this.photoDatas },
          })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
          // 照片打印机
          this.photoDatas = [];
          let photoUrls = [];
          for (var i = 0; i < this.photoList.length; i++) {
            if (this.photoList[i].status === true) {
              photoUrls.push(this.photoList[i].url);
            }
          }
          function loadImage(url) {
            return new Promise((resolve, reject) => {
              let img = new Image();
              img.setAttribute("crossOrigin", "Anonymous");
              img.onload = () => resolve(img);
              img.onerror = reject;
              img.src = url;
            });
          }
          function preLoadImg(source, photoDatas) {
            let pr = [];
            source.forEach((url) => {
              // 预加载图片
              let p = loadImage(url)
                .then((image) => {
                  //this.images.push(img))
                  console.log("00000000000000000");
                  let canvas = document.createElement("canvas");
                  canvas.width = image.width;
                  canvas.height = image.height;
                  console.log(image.width, image.height);
                  let context = canvas.getContext("2d");
                  context.drawImage(image, 0, 0, image.width, image.height);
                  let dataURL = canvas.toDataURL(); // 得到图片的base64编码数据
                  let payload = dataURL.slice(dataURL.indexOf("base64,") + 7);
                  photoDatas.push({ type: "image", payload: payload });
                  console.log(dataURL);
                  console.log("1111111111111111111");
                })
                .catch((err) => console.log(err));
              pr.push(p);
            });
            // 图片全部加载完
            Promise.all(pr).then(() => {
              console.log("2222222222222222222");
              instance2({
                url: "/print",
                method: "post",
                data: {
                  printer: "image",
                  data: photoDatas,
                },
              }).then((res) => {
                console.log(res);
              });
            });
          }
          let tmp = [];
          preLoadImg(photoUrls, tmp);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    preview() {
      //   let Base64 = require('js-base64').Base64
      this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
      if (!this.qrcodeErrors.length) {
        this.axios
          .get("/v1/picture/query/" + this.qrcode)
          .then((res) => {


            if(res.data.length!=8)
            {
                this.toastText = "照片处理未完成";
                this.showToast(this.toastText, {
                position: this.toastPosition,
                duration: this.toastDuration,
                fullWidth: this.isToastFullWidth,
                });
            }
            else if(res.data.length==8)
            {
                this.bigUrl = res.data[0];
                for (var i = 0; i < 8; i++) {
                    this.photoList[i]["url"] = res.data[i];
                    console.log(res.data[i]);
                }
            }

          })
          .catch((error) => {
            console.log(error);
            this.toastText = "照片处理过程出现错误";
            this.showToast(this.toastText, {
              position: this.toastPosition,
              duration: this.toastDuration,
              fullWidth: this.isToastFullWidth,
            });
          });
      } else {
        this.toastText = "请输入对应编号";
        this.showToast(this.toastText, {
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        });
      }
    },
    changeImage(idx) {
      //   console.log(idx);
      this.bigUrl = this.photoList[idx].url;
      this.photoList[idx].selected =
        this.photoList[idx].status == false ? "未选中" : "已选择";
    },
    checkNumber() {
      let count = 0;
      for (let i = 0; i < this.photoList.length; i++) {
        if (this.photoList[i].status == true) count = count + 1;
      }
      // 电子版
      if(count>0){
          this.emoney = 60;
      }

      // 打印版
      if(count==1)
      {
          this.pmoney = 20;
      }
      else if( count==2)
      {
          this.pmoney = 40;
      }
      else if( count==3)
      {
          this.pmoney = 60;
      }
      else if( count==4)
      {
          this.pmoney = 80;
      }
      else if( count==5)
      {
          this.pmoney = 100;
      }
      else if( count==6)
      {
          this.pmoney = 100;
      }
      else if( count==7)
      {
          this.pmoney = 120;
      }
      else if( count==8)
      {
          this.pmoney = 140;
      }
      else{
          this.pmoney = 0;
      }

      return count;
    },
    getdate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },
    gettime() {
      var date = new Date();
      var strHour = date.getHours();
      var strMinute = date.getMinutes();
      var strSecond = date.getSeconds();

      if (strHour >= 0 && strHour <= 9) {
        strHour = " " + strHour;
      }
      if (strMinute >= 0 && strMinute <= 9) {
        strMinute = "0" + strMinute;
      }
      if (strSecond >= 0 && strSecond <= 9) {
        strSecond = "0" + strSecond;
      }
      var currentTime = strHour + ":" + strMinute + ":" + strSecond;
      return currentTime;
    },
    showConfirmEorder() {

      this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
      if (!this.qrcodeErrors.length) {
        if (this.emoney == 0) {
            this.toastText = "请选择您喜爱的照片";
            this.showToast(this.toastText, {
            position: this.toastPosition,
            duration: this.toastDuration,
            fullWidth: this.isToastFullWidth,
            });
        }
        else
        {
            this.showEorder = true;
        }

      } else {
        this.toastText = "请输入对应编号";
        this.showToast(this.toastText, {
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        });
      }
    },
    showConfirmPorder() {

      this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
      if (!this.qrcodeErrors.length) {
          if (this.pmoney == 0) {
                this.toastText = "请选择您喜爱的照片";
                this.showToast(this.toastText, {
                position: this.toastPosition,
                duration: this.toastDuration,
                fullWidth: this.isToastFullWidth,
                });
            }
        else{
            this.showPorder = true;
        }
      } else {
        this.toastText = "请输入对应编号";
        this.showToast(this.toastText, {
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        });
      }
    },
    onCancel() {
      console.log("now cancel");
      //   this.deleteuser = "";
      //   console.log(this.deleteuser)
    },
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === '8') {
        console.log("Selected 8 pages");
        // Check all selected?
        let allSelected = true;
        for (let i = 0; i < 8; i++) {
          if (!this.photoList[i].status) {
            allSelected = false;
            break;
          }
        }

        for (let i = 0; i < 8; i++) {
          if (allSelected) {
            this.photoList[i].status = false;
            this.photoList[i].selected = "未选中";
          }
          else {
            this.photoList[i].status = true;
            this.photoList[i].selected = "已选择";
          }
        }
        this.checkNumber();
      }
    });
  },
};
</script>
